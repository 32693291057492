(function($) {

	$(document).ready( function() {



		$('.hamburger-container').click( function() {

			$('.nav-hamburger').toggleClass('active');

		});



		if ( parseInt( $("#mq-medium").css("max-width"), 10 ) <= 768 ) {
			//Less than 768px
		} else {
			//More than 768px
		}


		$( window ).resize(function() {
			
			if ( parseInt( $("#mq-medium").css("max-width"), 10 ) <= 768 ) {
				//Less than 768px
			} else {
				//More than 768px
			}

		});


	});

})(jQuery); // Fully reference jQuery after this point.